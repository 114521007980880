import { cn } from '@/shared/shadcn/lib/utils';

type ParagraphSmallProps = {
  children: React.ReactNode;
  className?: string;
} & React.HTMLAttributes<HTMLParagraphElement>;

export function ParagraphSmall({ children, className, ...rest }: ParagraphSmallProps) {
  return (
    <p className={cn('text-[10px] text-[#5A5A5A]', className)} {...rest}>
      {children}
    </p>
  );
}
