'use client';
import * as React from 'react';
import Avatar from '@/shared/assets/images/avatar.png';
import { Button } from '@/shared/shadcn/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/shared/shadcn/ui/dialog';
import { X } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { Form } from '@/shared/shadcn/ui/form';
import { zodResolver } from '@hookform/resolvers/zod';
import FormInput from '@/shared/components/FormInput';
import { Heading4 } from '@/shared/components/Heading4/Heading4';
import { Paragraph } from '@/shared/components/Paragraph/Paragraph';
import { ParagraphSmall } from '@/shared/components/ParagraphSmall/ParagraphSmall';
import Image from 'next/image';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/shared/shadcn/ui/select';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function BaseDialogShareLinkDocument({ open, setOpen }: Props) {
  const documentShareLinkSchema = z.object({
    name: z.string().min(1, { message: 'Name is required' }),
  });
  const form = useForm({
    defaultValues: {
      name: '',
    },
    resolver: zodResolver(documentShareLinkSchema),
  });

  const onSubmit = (data: any) => {
    console.log('data', data);
  };
  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogContent className='sm:max-w-[564px] bg-white gap-0'>
        <DialogHeader className='w-full flex items-center flex-row justify-between'>
          <DialogTitle className='font-bold text-lg'>
            Share Document
          </DialogTitle>

          <X
            color='#404040'
            className='cursor-pointer'
            onClick={() => setOpen(false)}
          />
        </DialogHeader>

        <DialogDescription>
          <Form {...form}>
            <form className='w-full' onSubmit={form.handleSubmit(onSubmit)}>
              <div className='flex flex-col items-start justify-start gap-5 w-full'>
                <div className='w-full'>
                  <FormInput
                    required={false}
                    name='people'
                    label=''
                    placeholder='Add people or divisions here'
                    control={form.control}
                    type='text'
                  />
                </div>
                <div className='w-full flex flex-col items-start'>
                  <Heading4 className='text-[#1A1A1A] font-semibold text-sm'>
                    Who can access
                  </Heading4>
                  <div className='flex items-center justify-between max-w-full w-full mt-2'>
                    <div className='flex flex-col items-start gap-3'>
                      <div className='flex items-start gap-3'>
                        <Image
                          src={Avatar}
                          alt='profile'
                          width={55}
                          height={55}
                          priority
                          quality={100}
                        />
                        <div className='flex flex-col items-start gap-1'>
                          <Paragraph className='text-[#404040] font-medium text-sm'>
                            Samuel Gondrong
                          </Paragraph>
                          <ParagraphSmall className='text-[#737373] font-medium text-[10px]'>
                            samuelgondrong@gmail.com
                          </ParagraphSmall>
                        </div>
                      </div>
                    </div>
                    <Paragraph className='text-[#404040] text-xs font-normal mb-3'>
                      Owner
                    </Paragraph>
                  </div>
                </div>

                <div className='w-full flex flex-col items-start'>
                  <Heading4 className='text-[#1A1A1A] font-semibold text-sm'>
                    Public access
                  </Heading4>
                  <div className='grid grid-cols-12 w-full max-w-full gap-5 mt-2'>
                    <div className='col-span-8'>
                      <Select>
                        <SelectTrigger className='w-full'>
                          <SelectValue placeholder='Anyone with the link' />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            <SelectItem value='Anyone with the link'>
                              Anyone with the link
                            </SelectItem>
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </div>
                    <div className='col-span-4'>
                      <Select>
                        <SelectTrigger className='w-full'>
                          <SelectValue placeholder='Can view only' />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            <SelectItem value='Anyone with the link'>
                              Anyone with the link
                            </SelectItem>
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </div>
                  </div>
                </div>
                <div className='w-full flex flex-col items-start'>
                  <Heading4 className='text-[#1A1A1A] font-semibold text-sm'>
                    Link
                  </Heading4>
                  <FormInput
                    required={false}
                    name='people'
                    label=''
                    placeholder='http://demo.etandatangan.com/'
                    control={form.control}
                    type='text'
                  />
                </div>
                <div className='w-full grid grid-cols-6'>
                  <div className='col-start-5 col-span-3 flex items-center gap-5'>
                    <Button
                      variant='outline'
                      className='border-none text-primary bg-[#FDF7F0] hover:text-primary w-full'
                      onClick={() => setOpen(false)}
                    >
                      Back
                    </Button>
                    <Button onClick={() => null} className='w-full'>
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </Form>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  );
}
