'use client';
import * as React from 'react';
import { useMenu } from '@/shared/constants';
import { ParagraphSmall } from '@/shared/components/ParagraphSmall/ParagraphSmall';
import Link from 'next/link';
import { Inter } from 'next/font/google';
import { usePathname } from 'next/navigation';
import DialogConfirm from '@/shared/components/DialogConfirm/DialogConfirm';
import useModal from '@/shared/hooks/useModal';
import { cn } from '@/shared/shadcn/lib/utils';

const inter = Inter({ subsets: ['latin'] });
export default function Sidebar() {
  const path = usePathname();
  const [subMenuCollapse, setSubMenuCollapse] = React.useState<boolean>(true);
  const menus = useMenu({ subMenuCollapse });

  const { isOpen, openModal, closeModal } = useModal();

  return (
    <div className={`flex flex-col gap-4 ${inter.className} pl-3`}>
      <div className='flex flex-col mt-6'>
        <ParagraphSmall className={cn('uppercase mx-3')}>MAIN</ParagraphSmall>
        <div className='flex flex-col gap-[8px]'>
          {menus.main.map((menu, index) => {
            return (
              <div
                key={index}
                className={`py-[5px] px-3 whitespace-nowrap flex max-w-full w-full gap-3 ${
                  path === menu.href
                    ? 'bg-secondary text-primary rounded w-[95%]'
                    : ''
                } ${
                  menu?.subMenus?.length
                    ? 'flex-col items-start'
                    : 'flex-row items-center hover:bg-secondary hover:text-primary hover:rounded hover:w-[95%]'
                }`}
              >
                <div
                  className={`flex flex-row items-center gap-3 justify-between max-w-full w-full ${
                    menu?.subMenus
                      ? 'hover:bg-secondary hover:text-primary hover:rounded hover:w-full cursor-pointer py-[5px] px-0 whitespace-nowrap'
                      : ''
                  }`}
                  onClick={() =>
                    menu.subMenus ? setSubMenuCollapse(!subMenuCollapse) : null
                  }
                >
                  <div className='flex max-w-full w-full gap-1 flex-row items-center'>
                    {menu.icons && <div className='icon'>{menu.icons}</div>}
                    {menu.href ? (
                      <Link
                        className={`text-sm font-medium text-[#5C5E64] hover:bg-secondary hover:text-primary max-w-full w-full p-1.5 hover:w-[80%] hover:rounded-md ${
                          path === menu.href ? 'text-primary' : ''
                        }`}
                        href={menu.href}
                      >
                        {menu.title}
                      </Link>
                    ) : (
                      <span className='text-sm ml-1.5 font-medium text-[#5C5E64]'>
                        {menu.title}
                      </span>
                    )}
                  </div>
                  {menu.collapseIcons && (
                    <div className='mx-5'>{menu.collapseIcons}</div>
                  )}
                </div>

                {subMenuCollapse && menu?.subMenus && (
                  <div
                    className={`ml-2 divide-x-4 relative flex flex-col items-start overflow-hidden transition-all duration-300`}
                  >
                    {menu.subMenus.map((subMenu, idx) => (
                      <div
                        key={idx}
                        className={`relative flex flex-row items-center ${
                          idx === 0 ? 'max-w-full w-full' : ''
                        }`}
                      >
                        {idx === 0 && (
                          <div className='absolute right-[136.5px] rotate-90'>
                            <hr className='text-red-600 bg-[#E5E7EB] p-0.5  w-20 max-w-full' />
                          </div>
                        )}
                        <Link
                          className={`text-sm font-medium text-[#5C5E64] p-3 max-w-full w-full ${
                            idx === 0 ? 'mx-4' : 'mx-3'
                          } ${
                            path === subMenu.href
                              ? 'text-primary bg-secondary rounded-md'
                              : ''
                          }`}
                          href={subMenu.href}
                        >
                          {subMenu.title}
                        </Link>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>

      <div className='flex flex-col'>
        <ParagraphSmall className={cn('uppercase mx-3')}>
          Settings
        </ParagraphSmall>
        <div className='flex flex-col gap-[8px]'>
          {menus.settings.map((menu, index) => {
            return (
              <div
                key={index}
                className={`py-[5px] px-3 whitespace-nowrap flex max-w-full w-full gap-3 ${
                  path === menu.href
                    ? 'bg-secondary text-primary rounded w-[95%]'
                    : ''
                } ${
                  menu?.subMenus?.length
                    ? 'flex-col items-start'
                    : 'flex-row items-center hover:bg-secondary hover:text-primary hover:rounded hover:w-[95%]'
                }`}
              >
                <div
                  className={`flex flex-row items-center gap-3 justify-between max-w-full w-full ${
                    menu?.subMenus
                      ? 'hover:bg-secondary hover:text-primary hover:rounded hover:w-full'
                      : ''
                  }`}
                >
                  <div className='flex flex-row items-center gap-1 max-w-full w-full'>
                    {menu.icons && <div className='icon'>{menu.icons}</div>}
                    {menu.href === '/logout' ? (
                      <div
                        className='text-sm font-medium text-[#5C5E64] hover:bg-secondary hover:text-primary max-w-full w-full p-1.5 hover:w-[80%] hover:rounded-md cursor-pointer'
                        onClick={openModal}
                      >
                        {menu.title}
                      </div>
                    ) : menu.href ? (
                      <Link
                        className={`text-sm font-medium text-[#5C5E64] hover:bg-secondary hover:text-primary max-w-full w-full p-1.5 hover:w-[80%] hover:rounded-md ${
                          path === menu.href ? 'text-primary' : ''
                        }`}
                        href={menu.href}
                      >
                        {menu.title}
                      </Link>
                    ) : (
                      <span className='text-sm font-medium text-[#5C5E64]'>
                        {menu.title}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <DialogConfirm
        open={isOpen}
        title='Log Out?'
        subtitle='Are you sure you want to log out?'
        primaryButton='Cancel'
        primaryButtonAction={closeModal}
        secondaryButton='Log Out'
        secondaryButtonAction={closeModal}
      />
    </div>
  );
}
