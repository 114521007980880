'use client';

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/shared/shadcn/ui/form';
import { Input } from '@/shared/shadcn/ui/input';

type FormInputProps = {
  name: string;
  label: string;
  placeholder: string;
  control: any;
  type: string;
  required?: boolean;
};

export default function FormInput({
  name,
  label,
  placeholder,
  control,
  type,
  required = false,
}: FormInputProps) {
  return (
    <FormField
      control={control}
      name={name as string}
      render={({ field }: any) => (
        <FormItem className='my-3'>
          <div className='flex flex-row items-center gap-x-1'>
            <FormLabel className="text-xs">{label}</FormLabel>
           {required && <span className='text-red-400'>*</span>}
          </div>
          <FormControl>
            <Input type={type} placeholder={placeholder} {...field} />
          </FormControl>
          <FormMessage className='text-red-400' />
        </FormItem>
      )}
    />
  );
}
