import { cn } from '@/shared/shadcn/lib/utils';

type Heading2 = {
    children: React.ReactNode;
    className?: string;
};

export function Heading2({ children, className }: Heading2) {
    return (
        <h2 className={cn('xl:text-[32px] text-2xl', className)}>{children}</h2>
    );
}
