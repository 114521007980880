'use client';

import * as React from 'react';
import { Button } from '@/shared/shadcn/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/shared/shadcn/ui/dialog';
import { X } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { Form } from '@/shared/shadcn/ui/form';
import { zodResolver } from '@hookform/resolvers/zod';
import FormInput from '@/shared/components/FormInput';
import { Textarea } from '@/shared/shadcn/ui/textarea';
import { cn } from '@/shared/shadcn/lib/utils';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function BaseDialogSendDocument({ open, setOpen }: Props) {
  const documentSendSchema = z.object({
    name: z.string().min(1, { message: 'Name is required' }),
  });
  const form = useForm({
    defaultValues: {
      name: '',
    },
    resolver: zodResolver(documentSendSchema),
  });

  const onSubmit = (data: any) => {
    console.log('data', data);
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogContent className='sm:max-w-[564px] bg-white gap-0'>
        <DialogHeader className='w-full flex items-center flex-row justify-between'>
          <DialogTitle className='font-bold'>Send Document</DialogTitle>

          <X
            color='#404040'
            className='cursor-pointer'
            onClick={() => setOpen(false)}
          />
        </DialogHeader>

        <DialogDescription className='mt-5'>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className='flex flex-col gap-3'>
                <label className='font-medium text-xs' htmlFor='message'>
                  Message
                </label>
                <Textarea
                  className={cn('border-[1px] border-[#A6A6A6]', 'text-xs')}
                  placeholder='Write your message here...'
                  maxLength={200}
                  name='message'
                  id='message'
                />
              </div>
              <FormInput
                required
                name='receiver'
                label='Receiver'
                placeholder="Enter the receiver's email address here"
                control={form.control}
                type='text'
              />
              <div className='grid grid-cols-6'>
                <div className='col-start-5 col-span-3 flex items-center gap-5'>
                  <Button
                    variant='outline'
                    className='border-none text-primary bg-[#FDF7F0] hover:text-primary w-full'
                    onClick={() => null}
                  >
                    Back
                  </Button>
                  <Button
                    // disabled={!value}
                    onClick={() => null}
                    className='w-full'
                  >
                    Send
                  </Button>
                </div>
              </div>
            </form>
          </Form>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  );
}
