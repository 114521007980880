import React from 'react';
import { Bell, Dot } from 'lucide-react';
import Image from 'next/image';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/shared/shadcn/ui/dropdown-menu';
import { Heading3 } from '../../Heading3/Heading3';
import { Paragraph } from '../../Paragraph/Paragraph';
import { Button } from '@/shared/shadcn/ui/button';
import { Heading4 } from '../../Heading4/Heading4';
import { ParagraphSmall } from '../../ParagraphSmall/ParagraphSmall';
import Avatar from '@/shared/assets/images/avatar.png';
import Link from 'next/link';

export default function Navigation({}) {
  return (
    <header className='border-b-[1px] border-[#D4D4D4]'>
      <nav className='container mx-auto h-full w-full xl:px-9 sm:px-6 md:px-6 lg:px-6 max-md:max-w-none relative py-3.5 px-6 flex flex-row items-center justify-between'>
        <div>
          <DemoLogo />
        </div>
        <div className='flex flex-row items-center gap-6'>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <div className='relative'>
                <Bell size={20} className='cursor-pointer' />

                <Dot size={24} color='#F04438' className='absolute -top-2.5' />
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className='w-[394px] mr-16 bg-white'>
              <DropdownMenuItem className='flex flex-col items-start'>
                <div className='flex items-center w-full justify-between mb-6'>
                  <Heading3 className='text-[#404040] text-base font-semibold'>
                    Notification
                  </Heading3>
                  <Paragraph className='text-primary text-xs cursor-pointer'>
                    Mark all as read
                  </Paragraph>
                </div>
                <div className='flex items-center gap-3'>
                  <Button size='sm' className='text-xs'>
                    Activity
                  </Button>
                  <Button size='sm' className='text-xs' variant={'secondary'}>
                    What New
                  </Button>
                </div>
                <div className='bg-[#FCF8F3] rounded-md p-3 gap-2 flex items-start my-3'>
                  <Image
                    src={Avatar}
                    width={40}
                    height={40}
                    alt='profile'
                    priority
                    quality={100}
                  />
                  <div className='flex flex-col items-start gap-2'>
                    <Heading4 className='text-[#404040] font-semibold text-xs'>
                      Document Ready for Signature
                    </Heading4>
                    <div className='flex items-start flex-col gap-1'>
                      <Paragraph className='text-[#404040] text-xs font-normal'>
                        Your document 'Contract_Agreement.pdf' needs your
                        signature. Please review and sign it when you're ready
                      </Paragraph>
                      <ParagraphSmall className='text-primary text-xs font-semibold'>
                        7 hours ago
                      </ParagraphSmall>
                    </div>
                  </div>
                </div>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>

          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Image
                src='/images/user.png'
                width={30}
                height={30}
                alt='profile'
                className='cursor-pointer'
                priority
              />
            </DropdownMenuTrigger>
            <DropdownMenuContent className='w-full  bg-white'>
              <DropdownMenuItem>
                <Link href='/settings'>My Profile</Link>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </nav>
    </header>
  );
}

const DemoLogo = () => {
  return (
    <Image
      src={'/images/logo.png'}
      width={100}
      height={100}
      alt='logo'
      priority
    />
  );
};
