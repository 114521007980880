'use client';

import * as React from 'react';
import { Heading2 } from '../../Heading2/Heading2';
import { Paragraph } from '../../Paragraph/Paragraph';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/shared/shadcn/ui/tooltip';
import { ChevronDown, Info } from 'lucide-react';
import { Inter } from 'next/font/google';
import Sidebar from '../Sidebar';
import Navigation from '../Nav';
import { Button } from '@/shared/shadcn/ui/button';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/shared/shadcn/ui/dropdown-menu';
import { DropdownMenuCheckboxItemProps } from '@radix-ui/react-dropdown-menu';
import { usePathname } from 'next/navigation';

// Dialog Document
import {
  BaseDialogDownloadDocument,
  BaseDialogShareLinkDocument,
  BaseDialogSendDocument,
} from '@/features/(MyDocuments)/my-documents/preview-document/components/BaseDialog/BaseDialog';

const inter = Inter({ subsets: ['latin'] });
type Props = {
  title: {
    text?: string;
    tooltip?: {
      text?: string;
      status?: boolean;
    };
    isTitle?: boolean;
  };
  description?: {
    text?: string;
    tooltip?: {
      text?: string;
      status?: boolean;
    };
    isDescription?: boolean;
  };
  children: React.ReactNode;
};

type Checked = DropdownMenuCheckboxItemProps['checked'];

export default function LayoutContent({ title, description, children }: Props) {
  const [dialogDocumentSend, setDialogDocumentSend] =
    React.useState<boolean>(false);
  const [dialogDocumentDownload, setDialogDocumentDownload] =
    React.useState<boolean>(false);
  const [dialogDocumentShareLink, setDialogDocumentShareLink] =
    React.useState<boolean>(false);
  const pathname = usePathname();
  const isPathnameDetail = pathname.split('/').length;

  return (
    <>
      <Navigation />
      <div
        className={`container mx-auto h-full w-full relative py-0 ${
          pathname.split('/')?.[1] === 'signature' ? '' : 'xl:pr-6 pr-0'
        }`}
      >
        {pathname.split('/')?.[1] === 'signature' ? (
          <div className={inter.className}>{children}</div>
        ) : (
          <div className='grid grid-cols-12 h-screen'>
            <aside className='xl:flex hidden col-span-2 border-r border-gray-300 w-full flex-col gap-y-3'>
              <Sidebar />
            </aside>
            <section className='xl:col-span-10 col-span-12 xl:pl-6 pl-4 py-6'>
              <section className='flex flex-col gap-y-10'>
                <div className='flex flex-row items-center max-w-full w-full justify-between'>
                  {title.tooltip ? (
                    <div className='flex flex-row items-start gap-x-2'>
                      <Heading2 className='mb-3 font-bold'>
                        {title.text}
                      </Heading2>
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Info
                              size={12}
                              color='#808080'
                              className='mt-3.5 mx-1'
                            />
                          </TooltipTrigger>
                          <TooltipContent>
                            {title?.tooltip?.text}
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </div>
                  ) : (
                    <div>
                      <Heading2 className='mb-3 font-bold'>
                        {title.text}
                      </Heading2>
                      <div className='flex items-center flex-row'>
                        <Paragraph className='text-[#808080]'>
                          {description?.text}
                        </Paragraph>
                        {title.tooltip ? null : (
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <Info
                                  size={12}
                                  color='#808080'
                                  className='mt-0.5 mx-1'
                                />
                              </TooltipTrigger>
                              <TooltipContent>
                                {description?.tooltip?.text}
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        )}
                      </div>
                    </div>
                  )}
                  {isPathnameDetail === 3 && (
                    <div className='grid grid-cols-3 gap-3'>
                      <Button className='font-semibold'>Sign Now</Button>
                      <Button className='bg-[#5569EF] font-semibold'>
                        Request Sign
                      </Button>
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button
                            className='bg-[#F7F7F7] text-[#5A5A5A] mx-3 font-medium border-[1px] border-[#E9E9E9]'
                            iconPosition='right'
                            icon={<ChevronDown color='#5A5A5A' size={20} />}
                          >
                            More
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className='sm:max-w-[600px] bg-white max-h-[90%]'>
                          <DropdownMenuCheckboxItem
                            className='text-sm font-normal cursor-pointer'
                            onClick={() => setDialogDocumentSend(true)}
                          >
                            Send
                          </DropdownMenuCheckboxItem>
                          <DropdownMenuCheckboxItem
                            className='text-sm font-normal cursor-pointer'
                            onClick={() => setDialogDocumentDownload(true)}
                          >
                            Download
                          </DropdownMenuCheckboxItem>
                          <DropdownMenuCheckboxItem
                            className='text-sm font-normal cursor-pointer'
                            onClick={() => setDialogDocumentShareLink(true)}
                          >
                            Share Link
                          </DropdownMenuCheckboxItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                  )}
                </div>
                <div className={inter.className}>{children}</div>
              </section>
            </section>
          </div>
        )}
      </div>

      {dialogDocumentSend && (
        <BaseDialogSendDocument
          open={dialogDocumentSend}
          setOpen={setDialogDocumentSend}
        />
      )}
      {dialogDocumentDownload && (
        <BaseDialogDownloadDocument
          open={dialogDocumentDownload}
          setOpen={setDialogDocumentDownload}
        />
      )}
      {dialogDocumentShareLink && (
        <BaseDialogShareLinkDocument
          open={dialogDocumentShareLink}
          setOpen={setDialogDocumentShareLink}
        />
      )}
    </>
  );
}
