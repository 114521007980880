import { cn } from '@/shared/shadcn/lib/utils';

type Heading4 = {
  children: React.ReactNode;
  className?: string;
};

export function Heading4({ children, className }: Heading4) {
  return <h4 className={cn('text-base', className)}>{children}</h4>;
}
