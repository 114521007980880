import { cn } from '@/shared/shadcn/lib/utils';

type Paragraph = {
    children: React.ReactNode;
    className?: string;
};

export function Paragraph({ children, className }: Paragraph) {
    return <small className={cn('font-normal', className)}>{children}</small>;
}
