import { Button } from '@/shared/shadcn/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/shared/shadcn/ui/dialog';
import { BadgeCheck, Info } from 'lucide-react';

type Props = {
  type?: 'Info' | 'Success';
  title?: string;
  subtitle?: string;
  primaryButton?: string;
  secondaryButton?: string;

  primaryButtonAction?: () => void;
  secondaryButtonAction?: () => void;

  open?: boolean;
  setOpen?: (open: boolean) => void;
};

export default function DialogConfirm({
  type = 'Info',
  title,
  subtitle,
  primaryButton,
  secondaryButton,

  primaryButtonAction,
  secondaryButtonAction,

  open = false,
  setOpen,
}: Props) {
  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogContent className='sm:max-w-[306px] bg-white'>
        <DialogHeader>
          {type === 'Info' && (
            <Info size={60} className='mx-auto' color='#F79009' />
          )}
          {type === 'Success' && (
            <BadgeCheck size={60} className='mx-auto' color='#12B76A' />
          )}

          <DialogTitle className='font-semibold text-center mt-10'>
            {title}
          </DialogTitle>
          <DialogDescription className='text-sm text-center mb-10'>
            {subtitle}
          </DialogDescription>
        </DialogHeader>

        <div className='flex justify-center gap-5'>
          {type === 'Info' && (
            <Button
              variant='outline'
              className='border-none text-primary bg-[#FDF7F0] hover:text-primary w-full'
              onClick={secondaryButtonAction}
            >
              {secondaryButton}
            </Button>
          )}
          <Button onClick={primaryButtonAction} className='w-full'>
            {primaryButton}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
