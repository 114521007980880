import {
  LayoutDashboard,
  FileText,
  File,
  ClipboardCheck,
  Settings,
  LogOut,
  ChevronDown,
  ChevronUp,
} from 'lucide-react';
import { StaticImageData } from 'next/image';

type NavItem = {
  title: string;
  href?: string;
  icons?: JSX.Element | React.ReactNode;
  collapseIcons?: JSX.Element | React.ReactNode;
  subMenus?: {
    title: string;
    href: string;
  }[];
};

type NAV_APP_PROPS = {
  main: NavItem[];
  settings: NavItem[];
};
export const useMenu = ({
  subMenuCollapse,
}: {
  subMenuCollapse: boolean;
}): NAV_APP_PROPS => {
  return {
    main: [
      {
        title: 'Dashboard',
        href: '/dashboard',
        icons: <LayoutDashboard size={20} />,
      },
      {
        title: 'Documents',
        icons: <FileText size={20} />,
        collapseIcons: subMenuCollapse ? (
          <ChevronUp size={20}  />
        ) : (
          <ChevronDown size={20} />
        ),
        subMenus: [
          {
            title: 'My Documents',
            href: '/my-documents',
          },
          {
            title: 'Awaiting Signature',
            href: '/awaiting-signature',
          },
          {
            title: 'Signed Documents',
            href: '/sign-document',
          },
          {
            title: 'Share With Me',
            href: '/share-with-me',
          },
        ],
      },
      {
        title: 'Assignment Status',
        href: '/assignment-status',
        icons: <ClipboardCheck size={20} />,
      },
      {
        title: 'Templates',
        href: '/templates',
        icons: <File size={20} />,
      },
    ],
    settings: [
      {
        title: 'Settings',
        href: '/settings',
        icons: <Settings size={20} />,
      },
      {
        title: 'Log Out',
        href: '/logout',
        icons: <LogOut size={20} />,
      },
    ],
  };
};

export enum listTypeProps {
  List = 'List',
  Grid = 'Grid',
}

export type GridFolderDataProps = {
  id: string;
  label: string;
  type: string;
  image: string | StaticImageData;
  nextFolder: [
    {
      id: string;
      label: string;
      type: string;
      image: string | StaticImageData;
      parent_id: string;
      slug: string;
    }
  ];
  parent_id: string;
  slug: string;
};

export type ListFolderDataProps = {
  id: number;
  label: string;
  type: string;
  parent_id: string;
  slug: string;
  image: string | StaticImageData;
  nextFolder: [
    {
      id: string;
      label: string;
      type: string;
      image: string | StaticImageData;
      parent_id: string;
      slug: string;
    }
  ];
  last_update: string;
  uploaded_by: string;
  size: string;
};
