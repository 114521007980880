import * as React from 'react';
import { Button } from '@/shared/shadcn/ui/button';
import OriginalDocument from '@/shared/assets/images/download-document.png';
import ModifiedDocument from '@/shared/assets/images/download-document-2.png';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/shared/shadcn/ui/dialog';
import { X } from 'lucide-react';
import Image from 'next/image';
import { Heading3 } from '@/shared/components/Heading3/Heading3';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function BaseDialogDownloadDocument({ open, setOpen }: Props) {
  const [tabDoc, setTabDoc] = React.useState<string>('');
  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogContent className='sm:max-w-[564px] bg-white'>
        <DialogHeader className='w-full flex items-center flex-row justify-between'>
          <div className='flex flex-col items-start'>
            <DialogTitle className='font-bold text-lg'>
              Document Download
            </DialogTitle>
            <DialogDescription className='text-[10px] text-[#404040] mt-1 mb-5'>
              This document has been modified, would you like to download the
              modified or original version
            </DialogDescription>
          </div>

          <X
            color='#404040'
            className='cursor-pointer'
            onClick={() => setOpen(false)}
          />
        </DialogHeader>

        <DialogDescription className='grid grid-cols-2 gap-3'>
          <div
            className={`min-h-[140px] cursor-pointer hover:bg-[#FEF0C7]/30 ${
              tabDoc === 'original-document'
                ? 'bg-[#FDF7F0]'
                : 'border-[1px] border-[#E9E9E9]'
            } rounded-2xl flex flex-col items-center justify-center gap-4`}
            onClick={() => setTabDoc('original-document')}
          >
            <Image
              src={OriginalDocument}
              width={50}
              height={50}
              priority
              alt='original-document'
            />
            <Heading3 className='text-[#404040] text-sm font-medium'>
              Original Document
            </Heading3>
          </div>
          <div
            className={`min-h-[140px] cursor-pointer hover:bg-[#FEF0C7]/30 ${
              tabDoc === 'modified-document'
                ? 'bg-[#FDF7F0]'
                : 'border-[1px] border-[#E9E9E9]'
            } rounded-2xl flex flex-col items-center justify-center gap-4`}
            onClick={() => setTabDoc('modified-document')}
          >
            <Image
              src={ModifiedDocument}
              width={50}
              height={50}
              priority
              alt='original-document'
            />
            <Heading3 className='text-[#404040] text-sm font-medium'>
              Modified Document
            </Heading3>
          </div>
        </DialogDescription>

        <div className='flex justify-end items-end gap-5'>
          <Button
            variant='outline'
            className='border-none text-primary bg-[#FDF7F0] hover:text-primary w-full'
            onClick={() => null}
          >
            Back
          </Button>
          <Button
            // disabled={!value}
            onClick={() => null}
            className='w-full'
          >
            Download
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
